export const olive = '#939786';
export const oliveLight = '#E1E5D4';
export const white = 'white';
export const black = 'black';
export const orange = '#D3752B';
export const yellow = '#F3BC48';
export const green = '#2C5666';
export const lightGreen = '#036564';
export const lightGreen2 = '#7DB0AF';
export const blue = '#183D57';
export const blueLight = '#CCE4FF';
export const brown = '#DED2B2';
export const gray = '#838383';
export const grayBackground = '#F5F4F3';
export const lightBrown = '#E9E4D7';
export const lightBrown2 = '#FEFDFA';
export const darkOrange = '#562A0D';
export const lightOrange = '#D8A67F';
export const coffe = '#E8D8C9';
export const red = '#9C4105';
export const grayDark = '#979797';

// Food categories colors
export const meat = '#D3752B';
export const bones = '#E4DDCB';
export const liver = '#562A0D';
export const giblets = '#C9B487';
export const veggie = '#2B6362';
export const fruits = '#F3BC48';
export const seeds = '#939786';
export const fish = '#2C5666';
export const fiber = '#E8D8C9';
export const other = '#3A87BD';
