import {
  LocalErrorBoundary,
  GlobalErrorBoundary,
} from './helpers/Errors.helper';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar.component';
import TabBar from './components/TabBar.component';
import { DataProvider } from './contexts/data.context';
import Dashboard from './pages/Dashboard.page';
import Home from './pages/Home.page';
import Login from './pages/Login.page';
import PrivateRoute from './pages/PrivateRoute.page';
import Signup from './pages/Signup.page';
import ForgotPassword from './pages/ForgotPassword.page';
import CreateNewFood from './pages/CreateNewFood.page';
import SearchFood from './pages/SearchFood.page';
import AddFood from './pages/AddFood.page';
import EditFood from './pages/EditFood.page';
import Profile from './pages/Profile.page';
import Register from './pages/Register.page';
import Training from './pages/Training.page';
import More from './pages/More.page';
import NotFound from './pages/NotFound.page';
import { ScrollToTop } from './helpers/Navigation.helper';

function App() {
  return (
    <BrowserRouter>
      <GlobalErrorBoundary>
        <ScrollToTop />
        <DataProvider>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
            }}
          >
            <NavBar />
            <div style={{ flex: 1, overflowY: 'visible' }}>
              <LocalErrorBoundary>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot" element={<ForgotPassword />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/register" element={<Register />} />
                  <Route element={<PrivateRoute />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/createNewFood" element={<CreateNewFood />} />
                    <Route path="/searchFood" element={<SearchFood />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/addFood" element={<AddFood />} />
                    <Route path="/editFood" element={<EditFood />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/training" element={<Training />} />
                    <Route path="/more" element={<More />} />
                  </Route>
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </LocalErrorBoundary>
            </div>
            <TabBar />
          </div>
        </DataProvider>
      </GlobalErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
