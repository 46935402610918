// Constants.helper.js

// Media query
export const smallScreen = '(max-width:849px)';
export const mediumlScreen = '(min-width: 850px) and (max-width: 1249px)';
export const largeScreen = '(min-width: 1250px)';

// Fonts
export const smallFontSize = '16px';
export const mainFontSize = '16px';

// Margins and Paddings
export const smallMargin = '5px';
export const mainMargin = '15px';
export const smallPadding = '5px';
export const mainPadding = '15px';
export const mainFormDevider = '3px';

// Border
export const smallBorderRadius = '5px';
export const mainBorderRadius = '10px';

// Estimated calories per 1kg dog weight
export const estCalories = 18.59;

// Presets
export const barfAdult = {
  meat: 70,
  bones: 10,
  liver: 5,
  giblets: 5,
  veggie: 7,
  seeds: 2,
  fruits: 1,
};

export const barfPuppy = {
  meat: 58,
  bones: 17,
  liver: 7,
  giblets: 7,
  veggie: 7,
  seeds: 3,
  fruits: 1,
};

export const barfTraditionalAdult = {
  meat: 70,
  bones: 10,
  liver: 5,
  giblets: 5,
  veggie: 10,
};

export const barfTraditionalPuppy = {
  meat: 58,
  bones: 17,
  liver: 7,
  giblets: 8,
  veggie: 10,
};

export const pmrAdult = {
  meat: 78,
  bones: 10,
  liver: 5,
  giblets: 5,
  fiber: 7,
};

export const pmrPuppy = {
  meat: 67,
  bones: 17,
  liver: 7,
  giblets: 7,
  fiber: 2,
};

export const pmrTraditionalAdult = {
  meat: 80,
  bones: 10,
  liver: 5,
  giblets: 5,
};

export const pmrTraditionalPuppy = {
  meat: 69,
  bones: 17,
  liver: 7,
  giblets: 7,
};
