/** @jsxImportSource @emotion/react */

import * as colors from './Colors';

const rowStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
};

const headerMealStyle = {
  display: 'flex',
  borderRadius: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  marginTop: '10px',
  backgroundColor: colors.lightBrown2,
};

const headerFoodStyle = {
  display: 'flex',
  borderRadius: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  backgroundColor: colors.lightBrown2,
};

const topButtonsContainerStyle = {
  ...rowStyle,
  justifyContent: 'space-between',
  margin: '10px 0 10px 0',
  textAlign: 'left',
  fontSize: '15px',
};

const fixedTopContainer = {
  position: 'sticky',
  top: 60,
  zIndex: 1000,
  backgroundColor: 'white',
  width: '100%',
};

const addFoodFormStyle = {
  maxWidth: '30%',
  minWidth: '100%',
};

const addFoodTitleStyle = {
  textAlign: 'center',
  color: colors.lightGreen,
  fontSize: '17px',
  fontWeight: 'bold',
};

const nutritionFactsTitleStyle = {
  textAlign: 'center',
  color: colors.lightGreen,
};

const addFoodButtonContainerStyle = {
  ...rowStyle,
  justifyContent: 'space-around',
};

const headingMealStyle = {
  color: colors.green,
};

const headingFoodStyle = {
  color: colors.green,
  width: '100%',
  textAlign: 'left',
};

const headingTotalStyle = {
  display: 'flex',
  alignItems: 'center',
  color: colors.green,
  backgroundColor: colors.oliveLight,
  padding: '5px',
  borderRadius: '10px',
};

const headingDeleteButonStyle = {
  color: colors.green,
};

const headerTextStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '90%',
  alignItems: 'center',
  margin: '0px',
  height: '50px',
};

const foodListStyle = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  listStyleType: 'none',
  padding: '0',
};

const foodListRowStyle = {
  display: 'flex',
  flexDirection: 'column',
  listStyleType: 'none',
  alignItems: 'center',
  textAlign: 'center',
  borderRadius: '10px',
  marginTop: '5px',
  marginLeft: '10px',
  marginRight: '10px',
  backgroundColor: colors.lightBrown2,
};

const inputFieldStyle = {
  width: '40px',
  marginLeft: '10px',
  marginRight: '10px',
};

const childConteinerStyle = {
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '10px',
  borderRadius: '10px',
  backgroundColor: colors.lightBrown,
};

const imageContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  margin: '10px 0 10px 0',
  borderRadius: '10px',
  heigh: '150px',
  overflow: 'hidden',
};

export {
  imageContainerStyle,
  headerMealStyle,
  addFoodButtonContainerStyle,
  headerFoodStyle,
  addFoodFormStyle,
  addFoodTitleStyle,
  headingFoodStyle,
  headingMealStyle,
  foodListRowStyle,
  inputFieldStyle,
  foodListStyle,
  headingDeleteButonStyle,
  headingTotalStyle,
  childConteinerStyle,
  headerTextStyle,
  nutritionFactsTitleStyle,
  topButtonsContainerStyle,
  fixedTopContainer,
};
